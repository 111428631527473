
  import { computed, defineComponent, onMounted, ref, nextTick } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import Multiselect from '@vueform/multiselect';
  import { useAbility } from '@casl/vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import { Actions } from '@/store/enums/StoreEnums';
  import FactorValuesTable from '@/views/manage/IRE/factors/FactorValuesTable.vue';
  import FactorValuesEditing from '@/views/manage/IRE/factors/FactorValuesEditing.vue';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import Factors from '@/store/enums/IREFactorsEnums';
  import Tabs from '@/components/tabs.vue';

  interface Factor {
    factorId: number;
    factorTitle: string;
  }

  export default defineComponent({
    name: 'factors-bathroom-values',
    components: {
      Multiselect,
      FactorValuesTable,
      FactorValuesEditing,
      Tabs,
    },
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const factorData = ref<Factor | undefined>();
      const editData = ref<Factor | undefined>();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('FactorsBathroomValues'), [
          translate('ire'),
        ]);
        reinitializeComponents();
      });

      await store.dispatch(Actions.GET_ALL_IRE_AREAS);
      const areas = computed(() => store.getters.ireAreasAllList);
      const selectedArea = ref<number>(areas.value[0].id);

      const refreshValues = async () => {
        await store.dispatch(Actions.GET_IRE_FACTOR_AREAS_VALUES, {
          areaId: selectedArea.value,
          factorId: Factors.BATHROOMS,
        });
      };

      await refreshValues();

      const handleAreaChange = async () => {
        if (factorData.value != undefined) {
          factorData.value.factorId = 0;
        }
        await nextTick();
        await refreshValues();
      };

      const factorsValues = computed(
        () => store.getters.ireFactorsAreasValuesList
      );

      const showFactorEditModal = (val) => {
        editData.value = val;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        factorData,
        editData,
        factorsValues,
        handleAreaChange,
        translate,
        selectedArea,
        areas,
        can,
        showFactorEditModal,
        refreshValues,
      };
    },
  });
